(function () {
  'use strict';

  angular
      .module('neo.home.entitats.entitat')
      .config(config);

  function config($stateProvider) {
    $stateProvider
        .state('home.entitats.entitat', {
          url: '/{entitatId}',
          abstract: true,
          templateUrl: 'home/entitats/entitat/entitat.tpl.html',
          controller: 'EntitatCtrl',
          controllerAs: 'vm',
          resolve: {
            entitat: function ($stateParams, Entitats, $q) {
              var deferred = $q.defer();

              Entitats.get({id: $stateParams.entitatId},
                  function (data) {
                    // returned 204
                    deferred.resolve(data);
                  },
                  function (response) {
                    deferred.reject(response);
                  }
              );
              return deferred.promise;
            },
            towns: function (Poblacions, $q) {
              var deferred = $q.defer();

              Poblacions.query(function (data) {
                    deferred.resolve(data)
                  },
                  function (response) {
                    deferred.reject(response)
                  }
              );
              return deferred.promise;
            }
          }
        });
  }
}());

